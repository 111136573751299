import React from 'react'
import Box from '@mui/material/Box'

type Props = {
  title: string
  children?: React.ReactNode
}

const ChartContainer: React.FC<Props> = ({ title, children }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '3em',
      }}
    >
      <h1 style={{ textAlign: 'center' }}>{title}</h1>
      {children}
    </Box>
  )
}

export default ChartContainer
