import data_EtekcityApex from './datasets/etekcity-apex.json'
import data_WithingsBody from './datasets/withings-body.json'

export type BucketName = string
export type DatasetName = 'etekcity-apex' | 'withings-body'
export type SentimentValue = 'pos' | 'neu' | 'mix' | 'neg'
type Keyword = string
type SentimentFrequency = { [key in SentimentValue]: number }

interface Summary {
  sentiments: SentimentFrequency
  keywords: { [key: Keyword]: number }
  sentimental_keywords: { [key: Keyword]: SentimentFrequency }
}

interface Buckets {
  keys: BucketName[]
  sentiments: { [key: BucketName]: SentimentFrequency }
  keywords: {
    [key: BucketName]: { [key: Keyword]: number }
  }
  sentimental_keywords: {
    [key: BucketName]: { [key: Keyword]: SentimentFrequency }
  }
}

interface DatasetContent {
  summary: Summary
  buckets: Buckets
}

export interface IndexedBucket {
  name: BucketName
  idx: number
}

export class Dataset {
  private readonly name: DatasetName
  private readonly data: DatasetContent
  public readonly indexedBuckets: IndexedBucket[]

  constructor(name: DatasetName) {
    this.name = name

    switch (name) {
      case 'etekcity-apex':
        this.data = JSON.parse(JSON.stringify(data_EtekcityApex))
        break
      case 'withings-body':
        this.data = JSON.parse(JSON.stringify(data_WithingsBody))
        break
      default:
        throw new Error(`No such dataset: ${name}`)
    }

    this.indexedBuckets = this.data.buckets.keys.map((b, idx) => ({
      name: b,
      idx,
    }))
  }

  public getData() {
    return this.data
  }

  public getOverallKeywords() {
    return this.data.summary.keywords
  }

  public getOverallSentiments() {
    return this.data.summary.sentiments
  }

  public getOverallSentimentalKeywords() {
    return this.data.summary.sentimental_keywords
  }

  public getSentimentForKeyword(keyword: string) {
    return this.data.summary.sentimental_keywords[keyword]
  }

  public getKeywordsByBucketName(bucketName: BucketName) {
    return this.data.buckets.keywords[bucketName]
  }

  public getSentimentsByBucketName(bucketName: BucketName) {
    return this.data.buckets.sentiments[bucketName]
  }

  public getSentimentalKeywordsByBucketName(bucketName: BucketName) {
    return this.data.buckets.sentimental_keywords[bucketName]
  }

  public getIndexedBuckets() {
    return this.indexedBuckets
  }

  static getBucketIndexByName = (
    indexedBuckets: IndexedBucket[],
    bucketName: BucketName,
  ) => {
    const val = indexedBuckets.find((b) => b.name === bucketName)
    if (!val) {
      throw new Error(`No such bucket: bucketName=${bucketName}`)
    }
    return val.idx
  }

  static getBucketNameByIndex = (
    indexedBuckets: IndexedBucket[],
    idx: number,
  ) => {
    const val = indexedBuckets.find((b) => b.idx === idx)
    if (!val) {
      throw new Error(`No such bucket: idx=${idx}`)
    }
    return val.name
  }
}

export const humanizeSentimentValue = (value: SentimentValue) => {
  switch (value) {
    case 'pos':
      return 'Positive'
    case 'neu':
      return 'Neutral'
    case 'mix':
      return 'Mixed'
    case 'neg':
      return 'Negative'
    default:
      throw new Error(`Unknown sentiment value: ${value}`)
  }
}
