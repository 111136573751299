import React from 'react'
import { OverallCharts, BucketCharts, BucketSelector, DatasetSelector } from './components'
import Container from '@mui/material/Container'
import { Dataset, BucketName, DatasetName } from './Data'

const App = () => {
  const [datasetName, setDatasetName] =
    React.useState<DatasetName>('etekcity-apex')
  const [bucketName, setBucketName] = React.useState<BucketName>('202301')

  const dataset = new Dataset(datasetName)
  const indexedBuckets = dataset.getIndexedBuckets()

  return (
    <div className="App" style={{ height: '100vh' }}>
      <Container
        maxWidth="md"
        className="AppContainer"
        style={{
          height: '100%',
          marginTop: '3em'
        }}
      >
        <DatasetSelector
          datasetName={datasetName}
          setDatasetName={setDatasetName}
          resetBucketName={() => setBucketName('202301')}
        />
        <OverallCharts datasetName={datasetName} />
        <BucketSelector
          bucketName={bucketName}
          indexedBuckets={indexedBuckets}
          onChange={(e, v) => {
            e.preventDefault()
            const bn = Dataset.getBucketNameByIndex(indexedBuckets, v as number)
            setBucketName(bn)
          }}
        />
        <BucketCharts datasetName={datasetName} bucketName={bucketName} />
      </Container>
    </div>
  )
}

export default App
