import React from 'react'
import ChartContainer from './ChartContainer'
import {
  BarChart,
  Tooltip,
  Legend,
  Bar,
  CartesianGrid,
  XAxis,
  Pie,
  PieChart,
  YAxis,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
} from 'recharts'
import {
  Dataset,
  DatasetName,
  BucketName,
  humanizeSentimentValue,
  SentimentValue,
} from '../Data'

type Props = {
  datasetName: DatasetName
  bucketName: BucketName
}

const Charts: React.FC<Props> = ({ datasetName, bucketName }) => {
  const dataset = new Dataset(datasetName)
  // const data = dataset.getData()

  const keywords = Object.entries(dataset.getKeywordsByBucketName(bucketName))
    .map(([k, v]) => ({
      count: v,
      keyword: k,
    }))
    .slice(0, 30)

  const sentiments = Object.entries(
    dataset.getSentimentsByBucketName(bucketName),
  ).map(([k, v]) => ({
    count: v,
    sentiment: humanizeSentimentValue(k as SentimentValue),
    fill:
      k === 'pos'
        ? '#b6d7a8'
        : k === 'neu'
        ? '#bcbcbc'
        : k === 'mix'
        ? '#f9cb9c'
        : k === 'neg'
        ? '#d5a6bd'
        : '#000',
  }))

  const sentimentalKeywords = Object.entries(
    dataset.getSentimentalKeywordsByBucketName(bucketName),
  ).map(([k, v]) => ({
    keyword: k,
    pos: v['pos'],
    neu: v['neu'],
    mix: v['mix'],
    neg: v['neg'],
    _sum: v['pos'] + v['neu'] + v['mix'] + v['neg'],
  }))
  const filteredSentimentalKeywords = sentimentalKeywords.filter(sk => sk._sum >= 3)

  return (
    <>
      <ChartContainer title={`${bucketName} - Keywords (Top 30)`}>
        <BarChart width={1200} height={150} data={keywords}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="keyword" />
          <YAxis dataKey="count" />
          <Tooltip />
          <Bar dataKey="count" fill="#475075" />
        </BarChart>
      </ChartContainer>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}>
      <ChartContainer title={`${bucketName} - Sentiments`}>
        <BarChart width={600} height={600} data={sentiments}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="sentiment" />
          <YAxis dataKey="count" />
          <Tooltip />
          <Bar dataKey="count" />
        </BarChart>
      </ChartContainer>
      <ChartContainer title={`${bucketName} - Keyword x Sentiments`}>
        <RadarChart
          outerRadius={150}
          width={600}
          height={600}
          data={filteredSentimentalKeywords}
        >
          <PolarGrid />
          <Tooltip />
          <PolarAngleAxis dataKey="keyword" />
          <Radar
            name="Positive"
            dataKey="pos"
            stroke="#457524"
            fill="#457524"
            fillOpacity={0.6}
          />
          <Radar
            name="Neutral"
            dataKey="neu"
            stroke="222"
            fill="#222"
            fillOpacity={0.6}
          />
          <Radar
            name="Mixed"
            dataKey="mix"
            stroke="#ff8001"
            fill="#ff8001"
            fillOpacity={0.6}
          />
          <Radar
            name="Negative"
            dataKey="neg"
            stroke="#bd0909"
            fill="#bd0909"
            fillOpacity={0.6}
          />
          <Legend />
        </RadarChart>
      </ChartContainer>
      </div>
    </>
  )
}

export default Charts
