import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import { DatasetName } from '../Data'

type Props = {
  datasetName: DatasetName
  setDatasetName: (_: DatasetName) => void
  resetBucketName: () => void
}

const DatasetSelector: React.FC<Props> = ({
  datasetName,
  setDatasetName,
  resetBucketName,
}) => {
  return (
    <FormControl fullWidth style={{ marginBottom: '3em' }}>
      <InputLabel id="dataset-select-label">Dataset</InputLabel>
      <Select
        labelId="dataset-select-label"
        id="dataset-select"
        value={datasetName}
        label="Dataset"
        onChange={(e) => {
          e.preventDefault()
          setDatasetName(e.target.value as DatasetName)
          resetBucketName()
        }}
      >
        <MenuItem value="withings-body">Withings Body</MenuItem>
        <MenuItem value="etekcity-apex">Etekcity Apex</MenuItem>
      </Select>
    </FormControl>
  )
}

export default DatasetSelector
