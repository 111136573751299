import React from 'react'
import Slider from '@mui/material/Slider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { BucketName, Dataset, IndexedBucket } from '../Data'

type Props = {
  bucketName: BucketName
  indexedBuckets: IndexedBucket[]
  onChange: (e: Event, v: number | number[]) => void
}

const BucketSelector: React.FC<Props> = ({
  bucketName,
  indexedBuckets,
  onChange,
}) => {
  const indexes = indexedBuckets.map((bi) => bi.idx)
  const min = Math.min(...indexes)
  const max = Math.max(...indexes)

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto',
        width: '700px',
      }}
    >
      <Typography id="input-slider" gutterBottom>
        Bucket (interval=month)
      </Typography>
      <Slider
        aria-label="Bucket"
        min={min}
        max={max}
        step={1}
      value={Dataset.getBucketIndexByName(indexedBuckets, bucketName)}
        onChange={onChange}
        style={{ marginBottom: '3em' }}
      />
    </Box>
  )
}

export default BucketSelector
