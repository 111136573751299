import React from 'react'
import ChartContainer from './ChartContainer'
import {
  BarChart,
  Tooltip,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
} from 'recharts'
import {
  Dataset,
  DatasetName,
} from '../Data'

type Props = {
  datasetName: DatasetName
}

const Charts: React.FC<Props> = ({ datasetName }) => {
  const dataset = new Dataset(datasetName)

  const keywords = Object.entries(dataset.getOverallKeywords()).map(([k, v]) => ({
    keyword: k,
    count: v,
  })).slice(0, 25)

  return (
    <>
      <ChartContainer title="Overall - Keywords (Top 25)">
        <BarChart width={1200} height={350} data={keywords}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="keyword" />
          <YAxis dataKey="count" />
          <Tooltip />
          <Bar dataKey="count" fill="#475075" />
          <Bar dataKey="pos" />
        </BarChart>
      </ChartContainer>
    </>
  )
}

export default Charts
